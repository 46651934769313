<template>
  <div>
    <a-form layout="inline" :model="searchFormState" style="margin-bottom: 20px;">
      <a-form-item label="姓名">
        <a-input
          v-model:value="searchFormState.wxNickname"
          placeholder="请输入姓名"
          class="searchInputWidth"
        ></a-input>
      </a-form-item>

      <a-form-item label="微信号" class="ml">
      <!-- <a-select placeholder="请输入微信号" v-model:value="searchFormState.roleId" class="searchInputWidth">
        <a-select-option
          :value="item.value"
          v-for="(item,index) in rolesOptions"
          :key="index"
        >{{ item.label }}</a-select-option>
      </a-select> -->
      <a-input
          v-model:value="searchFormState.wxNickname"
          placeholder="请输入微信号"
          class="searchInputWidth"
        ></a-input>
      </a-form-item>

    <a-form-item label="手机号">
        <a-input
          v-model:value="searchFormState.personTelphone"
          placeholder="请输入手机号"
          class="searchInputWidth"
        ></a-input>
      </a-form-item>

      <a-form-item style="margin-left: 20px;">
        <a-button type="primary" @click="search(searchFormState)" style="border-radius: 4px;">
          <template #icon>
            <search-outlined />
          </template>
          查询
        </a-button>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="clearFormState(searchFormState)">
          <template #icon>
            <redo-outlined />
          </template>
          重置
        </a-button>
      </a-form-item>
    </a-form>

    <!-- <div class="mb-20">
      <a-button type="primary" @click="operate('add')">
        <template #icon>
          <plus-outlined />
        </template>
        新增
      </a-button> -->

      <!-- <a-button @click="batchOperate('delete')" class="ml20">
        <template #icon></template>
        批量删除
      </a-button> -->
    <!-- </div> -->

    <!-- 表格数据展示 -->
    <a-table
      :columns="columns"
      :data-source="tabaleData"
      bordered
      :pagination="false"
      :rowKey="item => item.srMoveUserId"
    >
    <template #wxAvatar="{ record }">
        <a-image
          :height="30"
          :width="30"
          :src="`${record.wxAvatar}`"
        />
      </template>
      <!-- <template #operation="{ record }">
          <a style="color: #637CFE;" @click="operate('edit', record)">编辑</a>
          <a-divider type="vertical" />
          <a style="color: #637CFE;" @click="operate('delete', record)">删除</a>
      </template>
      <template #userEnable="{ record }">
        <div style="display: flex;align-items: center;justify-content: center;">
          <span :style="record.userEnable != 1 ? 'color: #6A6A6A;' : 'color: #D2D2D2;'">停用</span>
          <a-switch
            v-model:checked="record.switch"
            style="margin: 0 7px;"
            @change="simpleStopUse(record)"
          />
          <span :style="record.userEnable == 1 ? 'color: #6A6A6A;' : 'color: #D2D2D2;'">可用</span>
        </div>
      </template> -->
    </a-table>

    <div style="margin-top: 40px;display: flex;flex-direction: column;align-items: flex-end;">
      <a-pagination v-model:current="pagination.current" :total="pagination.total" v-if="tabaleData.length"
        @change="paginationChange" v-model:pageSize="pagination.pageSize" show-size-changer
        @showSizeChange="onShowSizeChange" :page-size-options="pagination.pageSizeOptions"
        :show-total="total => `共 ${total} 条`" show-quick-jumper />
    </div>

    <modal :title="modalState ? '新增':'编辑'" :width="780" ref="customDialog" @handleOk='sumbitEditForm'>
      <a-form name="custom-validation" ref="formRef" :model="formState" v-bind="layout" :rules="formStateRules">
        <a-form-item label="账号" name="wxNickname">
          <a-input v-model:value="formState.wxNickname" autocomplete="off" style="width:400px" policeholder="请输入账号" />
        </a-form-item>
        <a-form-item label="用户名" name="wxNickname" >
          <a-input v-model:value="formState.wxNickname" autocomplete="off" style="width:400px" policeholder="请输入用户名" :maxlength="10"/>
        </a-form-item>
        <a-form-item label="密码" name="password">
          <a-input v-model:value="formState.password" type="password" autocomplete="off" style="width:400px"
            policeholder="请输入密码" />
        </a-form-item>
        <a-form-item label="确认密码" name="confirmPass">
          <a-input v-model:value="formState.confirmPass" type="password" autocomplete="off" style="width:400px"
            policeholder="请输入确认密码" />

        </a-form-item>
        <a-form-item label="角色" name="roleId">
          <a-select placeholder="请选择角色" style="width: 400px;" v-model:value='formState.roleId'>

            <a-select-option :value="item.value" v-for="(item,index) in rolesOptions" :key="index"> {{item.label}}</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </modal>
  </div>
</template>

<script>
import {
  SearchOutlined,
  RedoOutlined,
  PlusOutlined
} from '@ant-design/icons-vue';
import { defineComponent, reactive, ref, onMounted } from 'vue';
import Modal from '@/components/modal.vue'
import { myPost, myGet } from "@/common/request.js"


const api= {
  // 查询
  queryPageUser: '/xkcwebApi/srMoveUser/querySrMoveUserPageList.htm',
}

export default defineComponent({
  components: {
    SearchOutlined,
    RedoOutlined,
    PlusOutlined,
    Modal
  },
  setup() {
    const customDialog = ref(null)

    const searchFormState = reactive(
      {
        wxNickname: undefined,
        wxNickname: undefined,
        personTelphone: undefined
      }
    )

    // 搜索查询
    const search = () => {
      queryList(1, searchFormState)
      // clearFormState()
    }

    const layout = {
      labelCol: {
        span: 7,
      },
      wrapperCol: {
        span: 13,
      },
    };


    let validator_re_pwd = async (rule, value) => {
        if (value !== formState.password) {
          return Promise.reject("两次输入不一致!");
        } else {
          return Promise.resolve();
        }
      };

    const formStateRules = {
        wxNickname: [{
          required: true,
          message: '账号不能为空',
          trigger: ['blur', 'change'],
          whitespace: true

        },
        {
          pattern: "^[a-zA-Z][a-zA-Z0-9_]*$",
          message: '账号字母开头，由字母，数字，下划线组成',
        }
        ],
        password: [
          {
            required: true,
            message: '密码不能为空',
            trigger: ['blur', 'change'],
            whitespace: true


          }
        ],
        confirmPass: [
          {
            required: true,
            message: '确认密码不能为空',
            trigger: ['blur', 'change'],
            whitespace: true

          },
          {
            validator: validator_re_pwd,
          }
        ],
        roleId: [
          {
            required: true,
            message: '角色不能为空',
            trigger: ['blur', 'change'],
            type: 'number'
          },
        ]
      }
    const sumbitEditForm = () => {
      // formRef.value.validate().then(() => {

      //   if (modalTitle.value == '新增') {
      //     let tempParam = {
      //       ...formState
      //     }
      //     delete tempParam.confirmPass
      //     tempParam.roleId = Number(tempParam.roleId)
      //     // 加密处理
      //     tempParam.wxNickname = encode64(tempParam.wxNickname)

      //     tempParam.password = encode64(tempParam.password)
      //     myPost(api.addUserUrl, tempParam).then((res) => {
      //       customDialog.value.closeModal()
      //       message.success('新增成功')
      //       queryList('', searchFormState)


      //     })
      //       .catch((err) => {
      //         console.log(err);
      //       })
      //   } else {
      //     let tempParam = {
      //       ...formState
      //     }
      //     delete tempParam.confirmPass
      //     tempParam.roleId = Number(tempParam.roleId)
      //     // 加密处理
      //     tempParam.wxNickname = encode64(tempParam.wxNickname)

      //     tempParam.password = encode64(tempParam.password)
      //     myPost(api.editUserUrl, tempParam).then((res) => {
      //       customDialog.value.closeModal()
      //       message.success(res.message)
      //       queryList('', searchFormState)

      //     })
      //       .catch((err) => {
      //         console.log(err);
      //       })
      //   }


      // })
      // .catch((err) => {
      //   console.log(err);
      // })
    }

    // 清除表单
    const clearFormState = (form, type) => {
      for (var i in form) {
        form[i] = null
      }
      type != 'handle' && queryList(1)
    }

    const rolesOptions = ref([])

    const modalState = ref(true)

    let formState = reactive({
        wxNickname: "",
        password: "",
        roleId: undefined,
        wxNickname: '',
        confirmPass: ''
      })

    const columns = [
      {
        title: '头像',
        dataIndex: 'wxAvatar',
        key: 'wxNickname',
        align: 'center',
        slots: {
          customRender: 'wxAvatar',
        }

      },
      {
        title: '微信名称',
        dataIndex: 'wxNickname',
        key: 'wxNickname',
        align: 'center'

      },

      {
        title: '手机号',
        dataIndex: 'personTelphone',
        key: 'personTelphone',
        align: 'center'

      },
      // {
      //   title: '微信号',
      //   dataIndex: 'recommend',
      //   align: 'center',
      //   slots: {
      //     customRender: true,
      //   }
      // },
      {
        title: '注册日期',
        dataIndex: 'createTime',
        align: 'center',
        slots: {
          customRender: true,
        }
      },
      {
        title: '最后登录时间',
        dataIndex: 'lastLoginTime',
        align: 'center'
      },
      // {
      //   title: '维护时间',
      //   dataIndex: 'maintenanceTime',
      //   align: 'center'
      // },
      // {
      //   title: '操作',
      //   dataIndex: 'operation',
      //   slots: {
      //     customRender: 'operation',
      //   },
      //   align: 'center',
      // }
    ];

    // 表格数据
    const tabaleData = ref([])

    const operate = (type, record) => {
      // formRef.value && formRef.value.resetFields()
      // clearFormState(formState, 'handle')
      modalState.value = type == 'add' ? true : false
      if (type == 'edit') {
        customDialog.value.showModal()
        // formState = Object.assign(formState, record)
        formState.wxNickname = record.wxNickname
        formState.password = record.password
        formState.roleId = record.roleId
        formState.id = record.id
        formState.wxNickname = record.wxNickname

      }
      if (type == 'add') {
        customDialog.value.showModal()

      }
      // if (type == 'delete') {

      //   let param = {
      //     id: record.id,

      //   }
      //   // console.log(api.deleteUserUrl, "ppppppppppppppppppp");
      //   myGet(api.deleteUserUrl, param).then(res => {
      //     message.success(res.message)
      //     queryList('', searchFormState)


      //   })
      // }
      // if (type == 'resetPassWord') {
      //   let param = {
      //     id: record.id,

      //   }
      //   // console.log(api.deleteUserUrl, "ppppppppppppppppppp");
      //   myGet(api.resetUserUrl, param).then(res => {
      //     message.success(res.message)
      //     queryList('', searchFormState)
      //   })
      // }
      // visibleEdit.value = true

      // 
    }
const queryList = (cur, condition = {}) => {
      if (cur) {
        pagination.value.current = cur
      }
      let param = {
        page: pagination.value.current,
        size: pagination.value.pageSize,
        ...condition
      }
      myPost(api.queryPageUser, param).then((res) => {
        tabaleData.value = res.data.map((item) => {
          return {
            ...item,
          }
        })
        pagination.value.total = res.total
      })
        .catch((err) => {
          console.log(err);
        })
    }
    onMounted(() => {
      queryList(1)
    })

        // 分页
    const pagination = ref({
      total: 50,
      current: 1,
      pageSize: 10,
      pageSizeOptions: ['10', '20', '30', '40', '50']
    })

    const paginationChange = (page, pageSize) => {
      // clearSelect()
      // selectDataId=[]
      pagination.value.page = page
      pagination.value.pageSize = pageSize
      queryList('', searchFormState)

    }
    const onShowSizeChange = (page, pageSize) => {
      // clearSelect()
      pagination.value.page = page
      pagination.value.pageSize = pageSize
      queryList('', searchFormState)


    }

    return {
      searchFormState,
      rolesOptions,
      customDialog,
      modalState,
      layout,
      formStateRules,
      sumbitEditForm,
      search,
      clearFormState,
      formState,
      tabaleData,
      columns,
      paginationChange,
      onShowSizeChange,
      pagination,
      operate
    }
  }
})
</script>

<style lang="scss" scoped>
.searchInputWidth {
  width: 220px;
}
.ml20 {
  margin-left: 20px;
}
.mb-20{
  margin-bottom: 20px;
}
</style>
